<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-6">Avaliações</h1>
    <p class="block text-base font-medium mb-2">
      Nota Média: {{ parseFloat(media).toFixed(1) }}
    </p>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Cliente
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Estrelas
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Observações
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data Envio
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data Resposta
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{
                      item.chat && item.chat.contato.nome
                        ? item.chat.contato.nome
                        : item.chat.contato.telefone
                    }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <star-rating
                      v-if="item.nota"
                      v-model="item.nota"
                      read-only
                      v-bind:star-size="40"
                    ></star-rating>
                    <span v-else>Avaliação sem resposta</span>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.obs ? item.obs : "" }}
                  </td>
                  <td v-if="item.data" class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                  </td>
                  <td v-if="item.dataResposta" class="text-sm 2xl:text-base px-2 py-3">
                    {{ item.dataResposta | moment("DD/MM/YYYY HH:mm") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    Breadcrumb,
    StarRating,
  },
  data() {
    return {
      breadcrumb: [
        { url: "/operadores", value: "Operadores" },
        { url: "/operadores/avaliacoes", value: "Avalições" },
      ],
      list: [],
      total: 0,
      media: 0,
    };
  },
  methods: {
    async start() {
      const operadoresReq = await this.$http.post("/v1/operador/avaliacao/list", {
        operador: this.$route.params.id
          ? this.$route.params.id
          : this.$store.state.user._id,
      });
      this.list = operadoresReq.data.data;
      this.total = operadoresReq.data.total;
      this.media = operadoresReq.data.media;
    },
  },
  async beforeMount() {
    this.start();
  },
};
</script>
